import { Typography, useTheme } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { useMaterialClasses } from './hooks'
import * as containerStyles from './bar.module.css'
import { get } from 'lodash'

export default function Bar() {
    const margin = useTheme().spacing(3)
    const { barButtonParent, barButtonChild, itemWithPadding } = useMaterialClasses()

    const { site } = useStaticQuery(graphql`query Bar {
        site {
            siteMetadata {
                title
                navigationBarLinks { title to }
            }
        }
    }`)

    const l = site.siteMetadata.navigationBarLinks as {title: string, to: string}[]

    return (
    <div style={{margin}}>
        <nav className={get(containerStyles, 'nav_wrapper')}>
            <div>
                <Typography
                    variant='button'
                    children={site.siteMetadata.title}
                    className={itemWithPadding}
                />
            </div>
            <div>
                {l.map(({title, to}, i) => (
                    <Typography
                        key={i}
                        component={Link}
                        variant='button'
                        to={to}
                        className={barButtonParent}
                    >
                        {title}
                        <Typography
                            variant='button'
                            children={title}
                            color='secondary'
                            className={barButtonChild}
                        />
                    </Typography>
                ))}
            </div>
        </nav>
    </div>)
}